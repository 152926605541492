
<template>
  <CRow>
    <CCol col="12" xl="12">
         <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="6">
             {{$t('message.salesPercentChart')}}
             <br/>
              <small>{{$t('message.fromDate') + ': ' + fromDate + ' - ' + $t('message.toDate') + ': ' + toDate}}</small>
            </CCol>

        </CRow>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.fromDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="fromDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder="$t('message.fromDate')"
                />
            </div>
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.toDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="toDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder=" $t('message.toDate')"
                />
            </div>
           <div class="col-md-2">
              <CButton square color="btn btn-primary" class="sub" v-on:click="filter" >
                    <CIcon class="c-icon" name="cil-chevron-top"/> &emsp;  {{$t('message.filter')}}
                </CButton>
            </div>
          </div>
          <hr/>
        <CChartLine
    :datasets="suppliers"
    :labels="dates"
  />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import $ from 'jquery'
import { CChartLine } from '@coreui/vue-chartjs'

const date = new Date()
const fromDate = (date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + '01')
const toDate = (date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0'))
export default {
  name: 'SalesReports',
  components: { CChartLine },
  computed: {
    defaultDatasets () {
      return [
        {
          label: 'Data One',
          backgroundColor: 'rgb(228,102,81,0.9)',
          data: [30, 39, 10, 50, 30, 70, 35]
        },
        {
          label: 'Data Two',
          backgroundColor: 'rgb(0,216,255,0.9)',
          data: [39, 80, 40, 35, 40, 20, 45]
        }
      ]
    }
  },
  data () {
    return {
      fromDate: fromDate,
      toDate: toDate,
      suppliers: [],
      dates: []
    }
  },
  created () {
    const dates = []
    const suppliers = []
    const colors = ['#6AFB92', '#00FFFF', '#800080', '#ADD8E6']
    this.$http
      .get(`${this.$hostUrl}report/sales-analysis?start=${this.fromDate}&end=${this.toDate}`)
      .then((response) => {
        $.each(response.data.data.data, function (key, value) {
          if (value.date) {
            dates.push(value.date)
          }
        })
        $.each(response.data.data.suppliersArray, function (key, value) {
          suppliers.push({
            label: value.name,
            backgroundColor: colors[key],
            data: value.data
          })
        })
        this.dates = dates
        this.suppliers = suppliers
      })
  },
  methods: {
    filter () {
      const dates = []
      const suppliers = []
      const colors = ['#6AFB92', '#00FFFF', '#800080', '#ADD8E6']
      this.$http
        .get(`${this.$hostUrl}report/sales-analysis?start=${this.fromDate}&end=${this.toDate}`)
        .then((response) => {
          $.each(response.data.data.data, function (key, value) {
            if (value.date) {
              dates.push(value.date)
            }
          })
          $.each(response.data.data.suppliersArray, function (key, value) {
            suppliers.push({
              label: value.name,
              backgroundColor: colors[key],
              data: value.data
            })
          })
          this.dates = dates
          this.suppliers = suppliers
        })
    }
  }
}
</script>
<style scoped>

</style>
